.recharts-text.recharts-label {
  font-size: 80%;
  fill: rgb(255, 255, 255);
}

.recharts-legend-item-text {
  font-size: 1rem;
}

.recharts-yAxis {
  font-size: 1rem;
}

.printFirstPage {
  break-after: page;
}

.printSection {
  break-after: page;
}

.footer {
  margin-top: 0.1rem;
  padding-left: 0.1rem;
  padding-top: 0.5rem;
  padding-right: 0.1rem;
  padding-bottom: 0.1rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100px !important;
}

body {
  padding-bottom: 100px;
}
@media print {
  #navigation {
    display: none !important;
  }
  .recharts-wrapper,
  .recharts-surface,
  .recharts-legend-wrapper {
    width: 100% !important;
  }
  .recharts-legend-item > svg {
    width: auto !important;
    display: inline-block !important;
    right: inherit !important; /* align to left */
  }
  .footer {
    padding: 0.1rem;
    height: 100px !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
